import React, { useState } from 'react'
import ViewPlaylist from './ViewPlaylist'
import ViewNewTrack from './ViewNewTrack'
import ViewMuznextPlaylist from './ViewMuznextPlaylist'

export default function SelectView(
    {
        spotifyApi,
        playlistsUser,
        playlistMuznext,
        idTrackNowPlay,
        setRemoveItemPlaylist
    }) {

    const [stateViewSelect, setStateViewSelect] = useState(false)
    const [stateViewPlaylist, setStateViewPlaylist] = useState(false)
    const [stateViewNewTrack, setStateViewNewTrack] = useState(false)
    const [stateViewMuznextPlaylist, setStateViewMuznextPlaylist] = useState(false)

    const boxViewSelect = () => {
        if (stateViewSelect) {
            if (stateViewPlaylist) {
                return <ViewPlaylist
                    spotifyApi={spotifyApi}
                    playlistMuznext={playlistMuznext}
                    idTrackNowPlay={idTrackNowPlay}
                    setStateViewSelect={setStateViewSelect}
                    setRemoveItemPlaylist={setRemoveItemPlaylist}
                />
            }
            if (stateViewNewTrack) {
                return <ViewNewTrack
                    spotifyApi={spotifyApi}
                    playlistMuznext={playlistMuznext}
                    idTrackNowPlay={idTrackNowPlay}
                    setStateViewSelect={setStateViewSelect}
                />
            }
            if (stateViewMuznextPlaylist) {
                return <ViewMuznextPlaylist
                    playlistsUser={playlistsUser}
                    idPlaylistMuzNext={playlistMuznext.id}
                    spotifyApi={spotifyApi}
                    setStateViewSelect={setStateViewSelect}
                />
            }
        }
    }

    const btnViewPLaylist = () => {
        setStateViewSelect(true)
        setStateViewPlaylist(true)
        setStateViewNewTrack(false)
        setStateViewMuznextPlaylist(false)
        boxViewSelect()
        setRemoveItemPlaylist(true)
    }

    const btnViewNewTrack = () => {
        setStateViewSelect(true)
        setStateViewPlaylist(false)
        setStateViewNewTrack(true)
        setStateViewMuznextPlaylist(false)
        boxViewSelect()
    }


    const btnViewUserPlaylist = () => {
        setStateViewSelect(true)
        setStateViewPlaylist(false)
        setStateViewNewTrack(false)
        setStateViewMuznextPlaylist(true)
        boxViewSelect()
    }

    return (
        <>
            <div className="box_btn_select-view">
                <button
                    className="btn_select-view"
                    onClick={() => btnViewUserPlaylist()}
                >
                    Mes Playliste(s) <br /> Spotify
                </button>
                <button
                    className="btn_select-view"
                    onClick={() => btnViewNewTrack()}
                >Ajouter <br />un Titre
                </button>
                <button
                    className='btn_select-view'
                    onClick={() => btnViewPLaylist()}
                >Voir <br />la Playlist
                </button>
            </div>
            <div className="box-view-select">
                {
                    boxViewSelect()
                }
            </div>
        </>
    )
}
