import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app';
import './userInfo.css'

export default function UserInfo({uid}) {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const disconnect = ()=> {
        firebase.auth().signOut();
    }

    useEffect(() => {
        if (uid !== false) {
            let db = firebase.database().ref('usersDB_Muznext');
            db.on('value', (snapshot) => {
                let dbUsersList = snapshot.val();
                let usersList = [];
                for (let id in dbUsersList) {
                    usersList.push({ id, ...dbUsersList[id] })
                }
                usersList.forEach(element => {
                    if (element.uid === uid) {
                        setName(element.name)
                        setEmail(element.email)
                    
                    }
                })
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <div id="userInfoPage">
            <h1 className="userInfoPageTitle">Information Utililsateur</h1>
            <button className="userInfoPageBtnDeconnect" onClick={()=>disconnect()}>
                DECONNEXION
            </button>
            <br /><br />
            <ul>
                <li>
                    <p>Nom : {name}</p>
                </li>
                <li>
                    <p>Email : {email}</p>
                </li>
            </ul>
        </div>
    )
}
