import SpotifyConnect from "../../config/Spotify/SpotifyConnect"

function GetUserSpotify(spotifyApi, setUserSpotify) {

    spotifyApi.getMe()
        .then(function (data) {
            setUserSpotify(data.body)
        }, function (err) {
            if (err.toString().includes('The access token expired') === true || err.toString().includes('Invalid access token') === true) {
                console.log('The access token expired or Invalid for User')
                SpotifyConnect()
            } else {
                console.log(err)
            }
        });
}

export default GetUserSpotify
