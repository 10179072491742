import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import { Link } from 'react-router-dom'
import { ContextUser } from '../../../config/context/Contextuser'
import './followEvent.css'

import NowPlayBox from './Views/NowPlayBox'
import Login from '../Login/Login'
import CalculBiddingTime from '../../Functions/CalculBiddingTime'


import SpotifyWebApi from "spotify-web-api-node"

var spotifyApi = new SpotifyWebApi();



export default function FollowEvent() {

    const userDb = useContext(ContextUser)

    const [accessToken, setAccessToken] = useState('')
    const [eventInfo, setEventInfo] = useState(false)
    const [errorAccess, setErrorAccess] = useState(true)
    const [token, setToken] = useState(false)
    const [trackPlayNow, setTrackPlayNow] = useState('')
    const [biddingInfo, setBiddingInfo] = useState('')
    // const [idPlaylistMuznext, setIdPlaylistMuznext] = useState('')
    const [inputArtistSearch, setInputArtistSearch] = useState('')
    const [musicResult, setMusicResult] = useState([])
    const [DjCoin, setDjCoin] = useState(0)
    const [DjCoinBidding, setDjCoinBidding] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)

    //search accesscode from url
    useEffect(() => {
        let tokenUrl = document.location.href
        let url = new URL(tokenUrl)
        setAccessToken(url.hash)
    }, [])

    // Get in Db info
    const [userEvent, setUserEvent] = useState(false)

    useEffect(() => {
        let db = firebase.database().ref('usersDB_Muznext');
        db.on('value', (snapshot) => {
            let dbUsersList = snapshot.val();
            let usersList = [];
            for (let id in dbUsersList) {
                usersList.push({ id, ...dbUsersList[id] })
            }
            usersList.forEach(user => {
                let userFollowAccessCode = user.currentEvent.AccessCode
                if (userFollowAccessCode === accessToken) {
                    setUserEvent(user)
                    setErrorAccess(false)
                    setEventInfo(user.currentEvent);
                    setToken(user.TOkenAccessSpotify)
                    setTrackPlayNow(user.trackNowPlay)
                    setBiddingInfo(user.bidding);
                    // setIdPlaylistMuznext(user.idPlaylistMuzNext)
                    spotifyApi.setAccessToken(user.TOkenAccessSpotify)
                    if (user.bidding.djCoin) {
                        setDjCoinBidding((user.bidding.djCoin))
                    }
                    else { setDjCoinBidding(0) }
                    setIsPlaying(user.is_Playing)
                }
            })
        })
    }, [accessToken])

    // Verif userConnected
    const [userConnected, setUserConnected] = useState(false)
    const fromFollowEvent = true

    useEffect(() => {
        if (userDb) {
            setUserConnected(true)
            setDjCoin(userDb.djCoin);
        }
    }, [userDb])


    // Verif BiddingWAR

    const [biddingWar, setBiddingWar] = useState(false)


    //Search Artist & Song
    const artistSearch = (a) => {
        spotifyApi.searchTracks(a).then(
            function (data) {
                setMusicResult(data.body.tracks.items)
            },
            function (err) {
                console.log('Something went wrong!', err);
            }
        );
    }

    const SearchArtist = (e) => {
        setInputArtistSearch(e.target.value)
        if (inputArtistSearch !== '') {
            artistSearch(inputArtistSearch)

        }
    }

    // new view for bidding War
    const [biddingWarInfo, setBiddingWarInfo] = useState(false)
    const [trackBiddingWar, setTrackBiddingWar] = useState(false)

    const newViewbiddingWar = (i) => {
        setBiddingWarInfo(true)
        setTrackBiddingWar(i)
    }

    // add Track
    const [djCoinSend, setDjCoinSend] = useState(1)

    useEffect(() => {
        setDjCoinSend(DjCoinBidding + 1)
    }, [DjCoinBidding])

    const addTrackForBidding = () => {
        const userWin = biddingInfo.userWin
        const oldTrackBidding = biddingInfo.trackId
        const usersDB = firebase.database().ref('usersDB_Muznext');
        usersDB.once('value', () => {
            let db = usersDB.child(userEvent.id);
            let bidding = {
                bidding: true,
                djCoin: djCoinSend,
                oldTrackId: oldTrackBidding,
                trackId: trackBiddingWar.id,
                user: userDb.name,
                userWin: userWin,
                userUID: userDb.uid
            }
            db.update({ bidding });


        })
    }

    // View
    return !errorAccess ? (
        <>
            <p className='info_connect'>evenement n°{accessToken}</p>
            {userConnected ? <p className='name_user'>{userDb.name}</p>  : null }
            <p className='Djcoin_info'>{DjCoin} DjCoins</p>
            <br /><br />
            <h1 className='name_of_event'>{eventInfo.nameOfTheParty}</h1>
            <hr />
            <NowPlayBox trackPlayNow={trackPlayNow} />
            <hr />
            {
                userConnected ? (
                    <>
                        {
                            isPlaying ? (
                                <>
                                    {/* <span>Temps restant pour les enchères :</span> */}
                                    <CalculBiddingTime token={token} trackPlayNow={trackPlayNow} setBiddingWar={setBiddingWar} />
                                </>
                            ) : null
                        }

                        {
                            biddingWar ? (
                                <>
                                    <br /><br />
                                    <h3 align="center">Choisis le prochain titre:</h3>
                                </>
                            ) : <div align='center'>
                                {
                                    biddingInfo.userWin === '' ? (
                                        <h3 className="nobodyBidding" align='center'>Personne a remporté l'enchère</h3>
                                    ) : (
                                        <h3 className="nobodyBidding" align='center'>{biddingInfo.userWin} a remporté l'enchère</h3>
                                    )
                                }
                            </div>
                        }
                        {!biddingWarInfo ? (
                            <>
                                <input type="text"
                                    className="inputSearchTrack"
                                    value={inputArtistSearch}
                                    placeholder="recherche ici un Artiste ou Titre"
                                    onChange={SearchArtist}
                                />

                                <ul>
                                    {
                                        musicResult.map((item, index) => {
                                            if (item.name === undefined) {
                                                return null
                                            } else {
                                                return (
                                                    <li className={'searchNextItem ' + index} style={{ fontSize: '0.8em' }} key={index}>
                                                        <img src={item.album.images[2].url} alt="" />
                                                        <span><strong>{' ' + item.artists[0].name}</strong><br />{' ' + item.name}</span>
                                                        <i className={'fas fa-play-circle ' + index}
                                                            onClick={(e) => {
                                                                newViewbiddingWar(item)
                                                            }}
                                                        ></i>
                                                    </li>
                                                )
                                            }
                                        })
                                    }

                                </ul>
                            </>
                        ) : (
                            <>
                                {trackBiddingWar ? (
                                    <>
                                        <i
                                            className="fas fa-backspace"
                                            style={{ fontSize: "2em", marginLeft: '10px' }}
                                            onClick={() => setBiddingWarInfo(false)}
                                        ></i>

                                        <div className="boxBiddingWarInfo">
                                            <img width='70' src={trackBiddingWar.album.images[0].url} alt="" />
                                            <div className="boxBiddingWarInfo-info">
                                                <p>{trackBiddingWar.name}</p>
                                                <p>{trackBiddingWar.artists[0].name}</p>
                                            </div>
                                        </div>
                                        <div className="boxBiddingWarInfo">
                                            {biddingWar ? (
                                                biddingInfo.user !== userDb.name ? (
                                                    <>
                                                        <input
                                                            className='input-biddingWar'
                                                            type="number"
                                                            value={djCoinSend}
                                                            min={DjCoinBidding}
                                                            step="1"
                                                            onChange={(e) => {
                                                                console.log(e.target.value);
                                                                setDjCoinSend(e.target.value)
                                                            }}
                                                        />

                                                        <button className="btn-bidding" onClick={() => addTrackForBidding()}>Enchérire</button>
                                                    </>
                                                ) : null
                                            ) : null}
                                        </div>
                                        {
                                            DjCoinBidding !== 0 ? (
                                                <>
                                                    <h3 align="center" style={{ padding: "10px", marginBottom: "20px", background: 'linear-gradient(to left, #fe8c00, #f83600)' }}>{biddingInfo.user} a enchérit avec {DjCoinBidding} DjCoins</h3>

                                                </>
                                            ) : (
                                                <h3 align="center" style={{ padding: "10px", marginBottom: "20px", background: 'linear-gradient(to left, #52c234, #061700)' }}>
                                                    {biddingWar ? 'Soit le premier à enchérire' : 'Attend le prochain tour'}
                                                </h3>
                                            )
                                        }
                                    </>
                                ) : null}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <h3 className='title-no-connect'>Connecte toi pour enchérir</h3>
                        <div className="login-follow-event">
                            <Login fromFollowEvent={fromFollowEvent} />
                        </div>
                    </>
                )
            }
            <br />
            {
                !trackBiddingWar && DjCoinBidding !== 0 ? (
                    <>
                        <h3 align="center" style={{ padding: "10px", marginBottom: "20px", background: 'linear-gradient(to left, #fe8c00, #f83600)' }}>{biddingInfo.user} a enchérit avec {DjCoinBidding} DjCoins</h3>

                    </>
                ) : null
            }
            <br /><br /><br /><br /><br />
        </>
    ) : (
        <>
            <p>Chargement...</p>
            <p>Est-tu sur de l'identifiant du spot ?</p>
            <Link to='/searchSpot'>RETOUR</Link>
        </>
    )
}
