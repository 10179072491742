import React, {useState, useEffect} from 'react'
import SpotifyPlayer from 'react-spotify-web-playback';
import firebase from 'firebase/app';

export default function Player({token, userDb, setIsPlaying, setIdTrackNowPlay, uriFirstTrackInPlaylist,changeStatusOfTrack,setChangeStatusOfTrack}) {

    const [nameTrack, setNameTrack] = useState('')
    const [artistTrack, setArtistTrack] = useState('')
    const [imgTrack, setImgTrack] = useState('')
    const [idTrack, setIdTrack] = useState('')
    const [uriTrack, setUriTrack] = useState('')

    
    const status = (e) => {
        if (e.status === "READY") {
            console.log(e);
            setNameTrack(e.track.name)
            setArtistTrack(e.track.artists)
            setImgTrack(e.track.image)
            setIdTrack(e.track.id)
            setUriTrack(e.track.uri)
            setIdTrackNowPlay(e.track.id)
            setIsPlaying(e.isPlaying)
            setChangeStatusOfTrack(!changeStatusOfTrack)
        }
    }

    // Send track play in Db
    useEffect(() => {
            const usersDB = firebase.database().ref('usersDB_Muznext');
            usersDB.once('value', () => {
            let db = usersDB.child(userDb.id);
            let trackNowPlay = {
                name: nameTrack,
                artist: artistTrack,
                uriTrack: uriTrack,
                img: imgTrack,
                idTrack: idTrack
            }
            if (token !== undefined && idTrack !== '') {
                db.update({ trackNowPlay });
            }
        })
    }, [idTrack,imgTrack,uriTrack,artistTrack,nameTrack,token,userDb])

    return uriFirstTrackInPlaylist ?  (
        <SpotifyPlayer
            className="player-muznext-app"
            name="MuzNext Player"
            token={token}
            persistDeviceSelection
            syncExternalDevice
            syncExternalDeviceInterval
            callback={status}
            uris={uriFirstTrackInPlaylist}
            styles={{
                activeColor: '#FFF',
                bgColor: '#000',
                color: '#fff',
                loaderColor: '#FFF',
                sliderColor: 'orange',
                sliderTrackColor: '#000',
                trackArtistColor: '#fff',
                trackNameColor: '#fff',
              }}
        />

    ) : <p>Chargement...</p>
}
