
export default function GetNowPLay(
    spotifyApi,
    setPlay,
    setIsPlaying
) {
    spotifyApi.getMyCurrentPlayingTrack()
        .then(function (data) {
            if (data.body === null) {
                setPlay(false)
                setIsPlaying(false);
            } else {
                if (data.body.item !== null) {
                    setTimeout(() => {
                        setPlay(data.body)
                        spotifyApi.getMyCurrentPlaybackState()
                        .then(function(data) {
                            setIsPlaying(data.body.is_playing);
                        }, function(err) {
                            console.log('Something went wrong!', err);
                        });
                    }, 500);
                }
            }
        }, function (err) {
            if (err.toString().includes('The access token expired') === true || err.toString().includes('Invalid access token') === true || err.toString().includes('No token provided') === true) {
                console.log('The access token expired or Invalid for PlayNow')
                // code  to reset token
            } else console.log(err)
        });

}
