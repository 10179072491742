import React from 'react'

function PageError() {
    return (
        <div>
            LA PAGE DEMANDEE N'EXISTE PAS 
        </div>
    )
}

export default PageError
