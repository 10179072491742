import React, { useState } from 'react'
import { motion } from 'framer-motion'
import firebase from 'firebase/app'
import logo from '../../../media/logo-login.gif'
import './login.css'
import newUser from '../../../config/user/newUser'

function Login({fromFollowEvent}) {

    const [hasAccount, setHasAccount] = useState(true)

    const [pseudo, setPseudo] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')

    const handleLogin = () => {
        clearErrors()
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                if (!fromFollowEvent) {
                    window.location.pathname = '/'
                }
            })
            .catch(error => {
                switch (error.code) {
                    case "auth/invalid-email":
                    case "auth/user-disabled":
                    case "auth/user-not-found":
                        setEmailError("L'adresse email n'est pas valide");
                        break;
                    case "auth/wrong-password":
                        setPasswordError("Le mot passe ou l'email n'est pas valide");
                        break;
                    default:
                }
            })
    }
    const handleSignup = () => {
        clearErrors()
        firebase
            .auth()
            .createUserWithEmailAndPassword(
                email,
                password
            )
            .then(() => {
                let user = firebase.auth().currentUser;
                user.updateProfile({ displayName: pseudo })
                newUser(
                    pseudo,
                    email,
                    user.uid
                )
                if (!fromFollowEvent) {
                    setTimeout(()=>{
                        window.location.pathname = '/'
                    },500)
                }
                
            })
            .catch(error => {
                switch (error.code) {
                    case "auth/email-already-in-use":
                    case "auth/invalid-email":
                        setEmailError("L'adresse email n'est pas valide");
                        break;
                    case "auth/weak-password":
                        setPasswordError("Le mot passe ou l'email n'est pas valide");
                        break;
                    default:
                }
            })
    }

    const clearErrors = () => {
        setEmailError('')
        setPasswordError('')
    }

    return (
        <motion.div 
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            className='container-login'
        >
            {
                !fromFollowEvent ?
                <img src={logo} alt="logo" className='logo-login' />
                : null
            }
            <div id='login'>
                <input
                    type="email"
                    placeholder='   Email'
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <p className="connect-errorMessage">{emailError}</p>
                {!hasAccount ?
                    <input
                        type="text"
                        placeholder='   Pseudo'
                        value={pseudo}
                        onChange={e => setPseudo(e.target.value)}
                    /> :
                    null
                }
                <input
                    type="password"
                    placeholder='   Mot de passe'
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                />
                <p className="connect-errorMessage">{passwordError}</p>

                {!hasAccount ?
                    <input
                        type="password"
                        placeholder='   Confirmer le mot de passe'
                        value={passwordConfirm}
                        onChange={e => setPasswordConfirm(e.target.value)}
                    /> :
                    null
                }
                <button onClick={() => { hasAccount ? handleLogin() : handleSignup() }}>
                    {hasAccount ? 'Se Connecter' : `S'inscrire`}
                </button>
                <div className="no-account">
                    <p>
                        {hasAccount ? 'Pas de compte ? ' : `J'ai  déjà un compte ! `}
                        <span onClick={() => setHasAccount(!hasAccount)}>
                            {hasAccount ? ` S'inscrir` : ' Connexion'}
                        </span></p>
                </div>
            </div>

        </motion.div>
    )
}

export default Login
