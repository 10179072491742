import React, { useState, useEffect, useContext } from 'react'
import firebase from 'firebase/app'
import { ContextUser } from '../../../config/context/Contextuser'

import Player from '../../SpotifyPlayer/Player'
// import WebPLayer from '../../SpotifyPlayer/WebPLayer'
import SelectView from './Views/SelectView'

import GetUserSpotify from '../../Functions/GetUserSpotify'
import GetPlaylists from '../../Functions/GetPlaylists'
import CalculBiddingTime from '../../Functions/CalculBiddingTime'

import './myEvent.css'
import logo from '../../../media/logo.png'
import QrCode from 'qrcode.react'

import SpotifyWebApi from "spotify-web-api-node"

var spotifyApi = new SpotifyWebApi();

function MyEvent() {

    const userDb = useContext(ContextUser)

    const [nameParty, setNameParty] = useState("")

    useEffect(() => {
        setNameParty(userDb.currentEvent.nameOfTheParty)
    }, [userDb])

    // Get token in url and  Send token in DB & SpotifyApi Token
    const [token, setToken] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)

    useEffect(() => {

        const TokenInURL = window.location.hash
            .substring(1)
            .split('&')
            .reduce((initial, item) => {
                var parts = item.split('=');
                initial[parts[0]] = decodeURIComponent(parts[1]);

                return initial;
            }, {});
        const hash = TokenInURL.access_token;
        setToken(hash);
        spotifyApi.setAccessToken(hash)

        const usersDB = firebase.database().ref('usersDB_Muznext');
        usersDB.once('value', () => {
            let db = usersDB.child(userDb.id);
            let TOkenAccessSpotify = token
            let is_Playing = isPlaying
            if (token !== undefined) {
                db.update({ TOkenAccessSpotify });
                db.update({ is_Playing })
            }


        })
    }, [token, userDb, isPlaying])

    // Get User Spotify
    const [userSpotify, setUserSpotify] = useState(false)

    useEffect(() => {
        if (token) GetUserSpotify(spotifyApi, setUserSpotify)
    }, [token])

    // Get Now Play
    const [idTrackNowPlay, setIdTrackNowPlay] = useState(false)

    // Get Playlists & Muznext Playlist & Position Track & Created Muznext Playlist
    const [playlistsUser, setPlaylistsUser] = useState(false)
    const [playlistMuznext, setPlaylistMuznext] = useState(false)
    const [uriFirstTrackInPlaylist, setUriFirstTrackInPlaylist] = useState(false)
    const [removeItemPlaylist, setRemoveItemPlaylist] = useState(false)
    const [changeStatusOfTrack, setChangeStatusOfTrack] = useState(false)


    useEffect(() => {
        if (userSpotify) {
            GetPlaylists(
                spotifyApi,
                userSpotify,
                idTrackNowPlay,
                setPlaylistsUser,
                setPlaylistMuznext,
                setUriFirstTrackInPlaylist
            )
            setRemoveItemPlaylist(false)
        }
    }, [token, idTrackNowPlay, userSpotify, removeItemPlaylist,changeStatusOfTrack])

    // Set QrCode Url
    const [urlCodeQr, setUrlCodeQr] = useState('')
    const url = process.env.REACT_APP_MY_EVENT_URL_QRCODE

    useEffect(() => {
        setUrlCodeQr(url + userDb.currentEvent.AccessCode)
    }, [url, userDb])


    // Calcul Bidding & Erase Db for new Bidding War & add track
    const [biddingWar, setBiddingWar] = useState(undefined)
    const [initBidding, setInitBidding] = useState(false)

    useEffect(() => {
        if (biddingWar !== undefined) {
            if (biddingWar) {
                setInitBidding(true)
            } else {
                if (initBidding) {
                    ///Add track
                    let arr = playlistMuznext.tracks.items
                    let idTrackAdd = userDb.bidding.trackId
                    let position
                    arr.map((element, index) => {
                        if (element.track.id === idTrackNowPlay) {
                            position = index
                        };
                        return null
                    })
                    if (position !== undefined) {
                        position = position + 1
                    } else position = 0
                    if (idTrackAdd !== '') {
                        spotifyApi.addTracksToPlaylist(playlistMuznext.id, [`spotify:track:${idTrackAdd}`], {
                            position: position
                        })
                            .then(function (data) {
                            }, function (err) {
                                console.log('Something went wrong!', err);
                                if (err.toString().includes('Error parsing parameter: position') === true) {
                                    console.log('erreur de parsing');
                                }
                            });
                    }

                    // send bidding erase in db
                    const userWin = userDb.bidding.user
                    const oldTrackBidding = userDb.bidding.trackId
                    const usersDB = firebase.database().ref('usersDB_Muznext');
                    usersDB.once('value', () => {
                        let db = usersDB.child(userDb.id);
                        let bidding = {
                            bidding: false,
                            djCoin: 0,
                            oldTrackId: oldTrackBidding,
                            trackId: '',
                            user: '',
                            userWin: userWin,
                            userUID: ''
                        }
                        db.update({ bidding });
                    })
                }
                setInitBidding(false)

            }
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [biddingWar, idTrackNowPlay])



    // View
    return (
        <>
            <p className='Spotify-info-connect'>
                Spotify <br /> {userSpotify.display_name ? 'connecté' : 'non connecté'}
            </p>
            <div className="container-event">
                <h1 className="name-of-party">{nameParty}</h1>
                {
                    token ?
                        <>
                            <Player
                                token={token}
                                userDb={userDb}
                                setIsPlaying={setIsPlaying}
                                setIdTrackNowPlay={setIdTrackNowPlay}
                                uriFirstTrackInPlaylist={uriFirstTrackInPlaylist}
                                changeStatusOfTrack={changeStatusOfTrack}
                                setChangeStatusOfTrack={setChangeStatusOfTrack}
                            />
                        {/* <WebPLayer  token={token}/> */}
                        </>
                        : null
                }
                <br />
                {
                    isPlaying ? <CalculBiddingTime token={token} trackPlayNow={idTrackNowPlay} setBiddingWar={setBiddingWar} /> : null
                }

                <SelectView
                    spotifyApi={spotifyApi}
                    playlistsUser={playlistsUser}
                    playlistMuznext={playlistMuznext}
                    idTrackNowPlay={idTrackNowPlay}
                    setRemoveItemPlaylist={setRemoveItemPlaylist}
                />
                <br />
                <p align="center">Soirée N° {userDb.currentEvent.AccessCode} </p>
                <QrCode
                    className="qrcode-access"
                    value={urlCodeQr}
                    level='M'
                    renderAs='svg'
                    bgColor='#fff'
                    fgColor="#000"
                    size={128}
                />
                <img style={{ display: 'block', margin: '20px auto' }} src={logo} width="250" alt="" />
                <br /><br /><br />
            </div>
            <br />
        </>
    )
}

export default MyEvent
