import firebase from '../firebase/firebase.js'


export default function newUser(name,email,uid) {
    const date = new Date()
    const user = {
        creationDate : date.toLocaleString(),
        name,
        sex: '',
        dateOfBirth: '',
        zipCode: '',
        email,
        grade:'Assistant DJ',
        djCoin:100,
        nbFreeCoinPlay:0,
        nbCoinPlay:0,
        nbOfEventCreate: 0,
        bidding:{
            bidding:false,
            djCoin:false,
            oldTrackId:'',
            trackId:'',
            user: '',
            userWin: '' ,
            userUID:''
        },
        currentEvent: {
            EventInCourt: false,
            AccessCode: '',
            nameOfTheParty: '',
            nbUserConnected: 0,  
            usersConnected: {
                0: {
                    userName:name,
                    urlPics: '',
                    timeConnected:'',
                    userUID:uid,
                    nbOfAuctions: 0
                }
            }
        },
        TOkenAccessSpotify: '',
        trackNowPlay:false,
        uid 
    }
    const usersDB = firebase.database().ref('usersDB_Muznext');
    usersDB.push(user);
}
