import React, { useState } from 'react'

export default function ViewNewTrack({
    spotifyApi,
    setStateViewSelect,
    playlistMuznext,
    idTrackNowPlay
}) {

    const [inputArtistSearch, setInputArtistSearch] = useState('')
    const [musicResult, setMusicResult] = useState([])


    //Search Artist & Song
    const artistSearch = (a) => {
        spotifyApi.searchTracks(a).then(
            function (data) {
                setMusicResult(data.body.tracks.items)
            },
            function (err) {
                console.log('Something went wrong!', err);
            }
        );
    }

    const SearchArtist = (e) => {
        setInputArtistSearch(e.target.value)
        if (inputArtistSearch !== '') {
            artistSearch(inputArtistSearch)

        }
    }

    //Add track
    const add = (item) => {
        let arr = playlistMuznext.tracks.items
        let uriTrackAdd = item.uri
        let position
        arr.map((element, index) => {
            if (element.track.id === idTrackNowPlay){ 
                position = index
            };
            return null
        });
        if (position!==undefined) {
            position =  position +1
        } else position = 0
        spotifyApi.addTracksToPlaylist(playlistMuznext.id, [uriTrackAdd], {
            position: position
        })
            .then(function (data) {
            }, function (err) {
                console.log('Something went wrong!', err);
                if (err.toString().includes('Error parsing parameter: position') === true) {
                    console.log('erreur de parsing');
                }
            });
    }

    const addStyle = (e) => {
        const item = e.target
        item.innerHTML = "Ajouté"
        item.style.color = 'green'
    }

    return (
        <>
            <div className='container'>
                <i
                    className="fas fa-backspace"
                    style={{ fontSize: "2em", marginLeft: '10px' }}
                    onClick={() => {
                        setInputArtistSearch('')
                        setMusicResult([])
                        setStateViewSelect(false)
                    }}
                ></i>
                <br />
                <h3 align="center">Choisis le prochain titre:</h3>

                <input type="text"
                    className="inputSearchTrack"
                    value={inputArtistSearch}
                    placeholder="recherche ici un Artiste ou un Titre"
                    onChange={SearchArtist} />
            </div>
            <br />
            <ul>
                {
                    musicResult.map((item, index) => {
                        if (item.name === undefined) {
                            return null
                        } else {
                            return (
                                <li className='searchNextItem' style={{ fontSize: '0.8em' }} key={index}>
                                    <img src={item.album.images[2].url} alt="" />
                                    <span><strong>{' ' + item.artists[0].name}</strong><br />{' ' + item.name}</span>
                                    <i className="fas fa-play-circle"
                                        onClick={(e) => {
                                            add(item)
                                            addStyle(e)
                                        }}
                                    ></i>
                                </li>
                            )
                        }
                    })
                }
            </ul>
        </>
    )
}
