import './App.css';
import React, { useState, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {ContextUser} from './config/context/Contextuser'
import firebase from './config/firebase/firebase';
import Loader from './media/loader.gif'

import NavBar from './components/NavBar/Navbar'
import Home from './components/Pages/Home/Home'
import Login from './components/Pages/Login/Login'
import UserInfo from './components/Pages/UserInfo/UserInfo'
import SearchSpot from './components/Pages/SearchSpot/SearchSpot'
import NewEvent from './components/Pages/NewEvent/NewEvent'
import MyEvent from './components/Pages/MyEvent/MyEvent'
import FollowEvent from './components/Pages/FollowEvent/FollowEvent'
import PageError from './components/Pages/Page404/PageError'

function App() {

  const [connected, setConnected] = useState(false)
  const [load, setLoad] = useState(true)
  const [uid, setUid] = useState(false)
  const [userDb, setUserDb] = useState(false)

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        setConnected(true)
        setUid(user.uid)

        let db = firebase.database().ref('usersDB_Muznext');
        db.on('value', (snapshot) => {
          let dbUsersList = snapshot.val();
          let usersList = [];
          for (let id in dbUsersList) {
            usersList.push({ id, ...dbUsersList[id] })
          }

          usersList.forEach(element => {
            if (element.uid === uid) {
              setUserDb(element);
            }
          })
        })

      }
      if (user === null) {
        setConnected(false)
      }

    })

    const timeLoad = setTimeout(() => {
      setLoad(false)
    }, [1500])
    return () => {
      clearTimeout(timeLoad)
    }
  }, [uid])



  return !load ? (
    <div className="App">
      <ContextUser.Provider value={userDb}>
      <Router>
        <NavBar connected={connected} />
        <Switch>
          <Route exact path='/'>
            {connected ? <Home userDb={userDb} /> : <Redirect to='/login' />}
          </Route>
          <Route path='/login'>
            {!connected ? <Login /> : <Redirect to='/' />}
          </Route>
          <Route path='/searchSpot'>
            <SearchSpot />
          </Route>
          <Route path='/user'>
            {connected ? <UserInfo uid={uid} /> : <Redirect to='/login' />}
          </Route>
          <Route path='/new-event'>
            <NewEvent userDb={userDb} />
          </Route>
          <Route path='/my-event' component={MyEvent} />
          <Route path='/follow-event' component={FollowEvent} />
          <Route component={PageError}/>
        </Switch>
      </Router>
      </ContextUser.Provider>
    </div>
  ) :
    (
      <div align='center'>
        <br /><br /><br />
        <p>Chargement...</p>
        <br /><br />
        <img width='200' style={{ borderRadius: '10px' }} src={Loader} alt="" />
      </div>
    )
}

export default App;
