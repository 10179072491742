import firebase from 'firebase/app'
import React, { useState, useEffect } from 'react'
import SpotifyConnect from '../../../config/Spotify/SpotifyConnect'
import './newEvent.css'

function NewEvent({userDb}) {

    const [nameOfParty, setNameOfParty] = useState('')
    const [errNameOfParty, setErrNameOfParty] = useState('')
    const [customNb, setCustomNb] = useState('')
    const [customNberr, setCustomNberr] = useState(false)

    const btnSpotifyConnect = () => {
        if(nameOfParty.length>2){
            pushCurrentEvent()
            setTimeout(()=>{
                SpotifyConnect()
            },[500])
        } else (
            setErrNameOfParty('Merci de mettre un nom de soirée')
        )
    }

    useEffect(() => {
        let nb = '#M' + String(10000 + Math.floor(99999 * Math.random()))
        let db = firebase.database().ref('usersDB_Muznext');
        db.on('value', (snapshot) => {
            let dbUsersList = snapshot.val();
            let usersList = [];
            for (let id in dbUsersList) {
                usersList.push({ id, ...dbUsersList[id] })
            }
            usersList.forEach((item)=>{
                if(item.currentEvent.AccessCode === nb) {
                    nb = '#M' + String(1 + Math.floor(99999 * Math.random()))
                    setCustomNberr(true)
                }
                else setCustomNb(nb);
            })
        })
    }, [customNberr])
    

    const pushCurrentEvent = ()=> {
        const usersDB = firebase.database().ref('usersDB_Muznext');
        usersDB.once('value', () => {
            let db = usersDB.child(userDb.id);
            const date = new Date()
            
            let currentEvent = {
                AccessCode : customNb,
                EventInCourt: true,
                nameOfTheParty: nameOfParty,
                nbUserConnected: 0,  
                usersConnected: {
                    0: {
                        userName: userDb.name,
                        urlPics: '',
                        timeConnected: date.toLocaleString(),
                        userUID: userDb.uid,
                        nbOfAuctions: 0
                    }
                }
            }
            db.update({currentEvent});
        })
    }

    return (
        <>
            <h1 className="newEventPageTitle" align="center">Création du Spot</h1>
            <div className="box-form-newEvent">
                <div className="form-newEvent">
                    <input 
                        className="form-input-newEvent" 
                        type="text" 
                        placeholder="Nom de la soirée" 
                        name="nameEvent"
                        value={nameOfParty}
                        onChange={(e)=>{setNameOfParty(e.target.value)}}
                        />
                    <br/><br/>
                    <span style={{color:'red'}}>{errNameOfParty}</span>
                    <br/>
                    <input 
                        className="btn-spotifyConnect" 
                        type="button" 
                        value="Connecter Spotify"
                        onClick={btnSpotifyConnect}
                        />
                </div>
            </div>
        </>
    )
}

export default NewEvent
