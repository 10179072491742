
export default function GetPlaylists(
    spotifyApi,
    userSpotify,
    idTrackNowPlay,
    setPlaylistsUser,
    setPlaylistMuznext,
    // setPositionTrack,
    setUriFirstTrackInPlaylist
) {

    spotifyApi.getUserPlaylists(userSpotify.id)
        .then(function (data) {
            let arr = data.body.items;
            setPlaylistsUser(arr)
            let noPlaylist = 0
            arr.forEach((item, index) => {
                if (item.name === "MUZNEXT-APP") {
                    setUriFirstTrackInPlaylist(item.uri)
                    spotifyApi.getPlaylist(item.id)
                        .then(function (data) {
                            let arr = data.body.tracks.items
                            // let arrayUri = []
                            setPlaylistMuznext(data.body)
                            arr.forEach((item, index) => {
                                // arrayUri.push(item.track.uri)
                                if (item.track.id === idTrackNowPlay) {
                                    // setPositionTrack(index)
                                }
                            })
                            // setUriFirstTrackInPlaylist(arrayUri)

                            // loop repeat
                            // spotifyApi.setRepeat('context')
                            //     .then(function () {
                            //         // console.log('Repeat track.');
                            //     }, function (err) {
                            //         //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
                            //         console.log('Something went wrong!', err);
                            //     });
                        }, function (err) {
                            console.log('Something went wrong!', err);
                        });
                } else {
                    noPlaylist++
                    if (noPlaylist === arr.length) {
                        spotifyApi.createPlaylist('MUZNEXT-APP', { 'description': 'Playlist de Muznext, le jukebox numérique', 'public': false })
                            .then(function (data) {
                                // console.log('Created playlist!');
                                // loop repeat
                                spotifyApi.setRepeat('context')
                                    .then(function () {
                                        // console.log('Repeat track.');
                                    }, function (err) {
                                        //if the user making the request is non-premium, a 403 FORBIDDEN response code will be returned
                                        console.log('Something went wrong!', err);
                                    });
                            }, function (err) {
                                console.log('Something went wrong!', err);
                            });
                    }
                }
            })

        }, function (err) {
            console.log('Something went wrong!', err);
        });
}
