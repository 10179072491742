import React, { useState } from 'react'

export default function ViewMuznextPlaylist({ playlistsUser, spotifyApi,idPlaylistMuzNext,setStateViewSelect }) {
    const [playlistUserAddName, setPlaylistUserAddName] = useState('')

    return (
        <>
            <i
                className="fas fa-backspace"
                style={{ fontSize: "2em", marginLeft: '10px' }}
                onClick={() => {
                    setStateViewSelect(false)
                }}
            ></i>
            <br /><br />
            <h3 align="center" style={{ fontWeight: 'bold', color: 'orange' }}>{playlistUserAddName}</h3>
            {
                playlistsUser !== false ? (
                    <>
                        {playlistsUser.map((item, key) => {
                            if (item.name !== 'MUZNEXT-APP' && item.tracks.total > 0) {
                                return (
                                    <li className='playlistItem' style={{ background: 'linear-gradient(to right, #000000, #434343)' }} key={key} >
                                        <img width="50" src={item.images[0].url} alt="muznext" />
                                        <span><strong className="artisteName">{item.name}</strong></span><br /><br />
                                        <span>{item.tracks.total} titres</span>
                                        <span width="30"></span>
                                        <i className="fas fa-plus"
                                            onClick={() => {
                                                spotifyApi.getPlaylist(item.id)
                                                    .then(function (data) {
                                                        let tracks = data.body.tracks.items
                                                        tracks.map((item) => {
                                                            spotifyApi.addTracksToPlaylist(idPlaylistMuzNext, [`spotify:track:${item.track.id}`])
                                                                .then(function (data) {
                                                                    // console.log('Added tracks to playlist!');
                                                                }, function (err) {
                                                                    console.log('Something went wrong!', err);
                                                                }
                                                            )
                                                            return null
                                                        })

                                                    }, function (err) {
                                                        console.log('Something went wrong!', err);
                                                    });
                                                setPlaylistUserAddName('playlist : ' + item.name + ' ajouté')
                                            }}
                                        ></i>
                                    </li>
                                )
                            } else return null
                        })}
                        <br /><br />
                    </>
                ) : <p>Aucune playlist</p>
            }
        </>
    )
}
