import React, { useState } from 'react'
import { motion } from 'framer-motion'
import logo from '../../../media/logo-login.gif'
import { Link } from 'react-router-dom'

import './searchSpot.css'
function SearchSpot() {

    const [codeSpot, setCodeSpot] = useState('#M')

    const btnSearchEvent = () => {
        let url = "follow-event?access=" + codeSpot
        return url
    }

    return (
        <div className="container-search-spot">
            <img src={logo} alt="logo" className='logo-login' />

            <motion.div
                id='login'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
            >
                <h3 className='title-input-search-spot'>Code du Spot</h3>
                <input type="text" placeholder='   Code Spot' value={codeSpot} onChange={e => setCodeSpot(e.target.value)} />
                <Link to={btnSearchEvent}>
                <button onClick={() => console.log('Connection au spot : ' + codeSpot)}>
                    Connexion au SPOT 
                    <i className="fas fa-check-circle" />
                </button>
                </Link>
            </motion.div>
        </div>
    )
}

export default SearchSpot
