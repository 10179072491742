import React, {useState, useEffect} from 'react'



export default function NowPLayBox({trackPlayNow}) {

    const [img, setImg] = useState('')
    const [name, setName] = useState('')
    const [artist, setArtist] = useState('')

    useEffect(() => {
        if (trackPlayNow !== undefined) {
            setImg(trackPlayNow.img)
            setName(trackPlayNow.name)
            setArtist(trackPlayNow.artist)
        }
    }, [trackPlayNow])

    return (
        <div className='playbox-info'>
            <img src={img} alt="" />
            <div className="box-name-artist-and-album">
                <p>{name}</p>
                <p>{artist}</p>
            </div>
        </div>
    )
}
