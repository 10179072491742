import React, { useState, useEffect, useRef } from 'react'
import SpotifyWebApi from 'spotify-web-api-node'
import GetNowPlay from '../Functions/GetNowPLay'


export default function CalculBiddingTime({ token,trackPlayNow , setBiddingWar}) {

    var spotifyApi = new SpotifyWebApi();


    const interval = useRef(null);

    // Get NOW Play
    const [isPlaying, setIsPlaying] = useState(false)
    const [play, setPlay] = useState(false)

    useEffect(() => {
        if (token) {
            spotifyApi.setAccessToken(token)
            GetNowPlay(
                spotifyApi,
                setPlay,
                setIsPlaying
            )
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, trackPlayNow])

    useEffect(() => {
        if (!isPlaying) {
            clearInterval(interval.current)
        }
    }, [isPlaying])

    // Set  states, Progress & duration  track
    const [progressMS, setProgressMS] = useState(false)
    const [durationMs, setDurationMs] = useState(false)

    useEffect(() => {
        if (play) {
            setProgressMS(play.progress_ms)
            setDurationMs(play.item.duration_ms)
        }
    }, [play, trackPlayNow])

    // set Time bidding
    const [elapsedForBidding, setElapsedForBidding] = useState(0)

    useEffect(() => {
        if (progressMS) {
            setElapsedForBidding(progressMS)
        }
        
    }, [progressMS ])

    const calculTime = (t) => {
        let time = t / 1000
        let min = 0;
        for (let i = time; i > 59; i = i - 60) {
            min++;
            time = i - 60;
        }
        let sec = time.toFixed(0)
        if (sec.length === 1) { sec = '0' + sec }
        let elapsed = min + 'm : ' + sec + 's';
        return elapsed
    }

    //Calcul BiddingTime remaining 
    const [timeOverForBidding, setTimeOverForBidding] = useState('')
    const [progressBar2, setProgressBar2] = useState(0);

    useEffect(() => {
        elapsed(elapsedForBidding)
        remainingBiddingTime(elapsedForBidding)
        return ()=> {
            clearInterval(interval.current)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elapsedForBidding ])

    const elapsed = (t) => {
        clearInterval(interval.current)
        interval.current = setInterval(() => {
            let timeProgress = t + 1000
            setProgressMS(timeProgress)
        }, [1000])
    }

    const remainingBiddingTime = (t) => {
        let time = (durationMs - elapsedForBidding) - 30000
        if (time > 0) {
            let timeEnd = calculTime(time)
            setTimeOverForBidding(timeEnd)
            setBiddingWar(true)
        } else if (time < 0){
            setTimeOverForBidding('Prochain Round dans un instant')
            setBiddingWar(false)
        }
        let progressBarStyle = ((elapsedForBidding / (durationMs - 30000)) * 100).toFixed(2)
        if (progressBarStyle < 100) {
            setProgressBar2(progressBarStyle);
        } else setProgressBar2(100);
    }



    //style css 

    const progressBarStyle2 = {
        position: 'absolute',
        width: progressBar2 + '%',
        height: '100%',
        backgroundImage: 'radial-gradient(circle at center right, rgb(253, 97, 39) 0%, rgb(253, 97, 39) 14.286%,rgb(251, 108, 38) 14.286%, rgb(251, 108, 38) 28.572%,rgb(249, 118, 37) 28.572%, rgb(249, 118, 37) 42.858%,rgb(247, 129, 37) 42.858%, rgb(247, 129, 37) 57.144%,rgb(245, 140, 36) 57.144%, rgb(245, 140, 36) 71.43%,rgb(243, 150, 35) 71.43%, rgb(243, 150, 35) 85.716%,rgb(241, 161, 34) 85.716%, rgb(241, 161, 34) 100.002%)',
        boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',
        transition: '0.3s',
        top: '0px',
        left: '0px',
    }

    const progressBarStyle2a = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundImage: 'radial-gradient(circle at center , rgb(46, 46, 46) 0%, rgb(46, 46, 46) 6%,rgb(41, 41, 41) 6%, rgb(41, 41, 41) 27%,rgb(36, 36, 36) 27%, rgb(36, 36, 36) 42%,rgb(31, 31, 31) 42%, rgb(31, 31, 31) 63%,rgb(25, 25, 25) 63%, rgb(25, 25, 25) 64%,rgb(20, 20, 20) 64%, rgb(20, 20, 20) 71%,rgb(15, 15, 15) 71%, rgb(15, 15, 15) 100%)',
        boxShadow: '0px 0px 5px rgba(0,0,0,0.5)',
        transition: '0.3s',
        top: '0px',
        left: '1px',
    }

    return isPlaying ? (
        <>
            <div style={{
                position: 'relative',
                display: 'block',
                margin: '0 auto',
                width: '100%',
                height: '60px',
                overflow: 'hidden'
            }}
                className="container-progressBidding"
            >
                <span style={progressBarStyle2a} />
                <span style={progressBarStyle2} />
                <span
                    className='timmingBidding'
                    style={{
                        position: 'absolute',
                        transition: '0.3s',
                        width: '100%',
                        textAlign: 'center',
                        fontWeight: 'bold',
                        textShadow: '2px 2px 10px #000',
                        marginTop: '30px'
                    }}
                >
                    {timeOverForBidding}
                </span>
            </div>
        </>
    ) : null
}
