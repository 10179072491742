import React from 'react'
import './navbar.css'
import { Link } from 'react-router-dom'

function Navbar({ connected }) {


    return (
        <nav id="principal-Nav">
            <ul className="principal-Nav-ul">
                <li className="principal-Nav-ul-li">
                    <Link className='link-router' to='/'>
                        <i className="fas fa-home"></i>
                    </Link>
                </li>
                <li className="principal-Nav-ul-li">
                    <Link className='link-router' to='/searchSpot'>
                        <i className="fas fa-search-location"></i>
                    </Link>
                </li>
                {connected ? (
                    <li className="principal-Nav-ul-li">
                        <Link className='link-router' to='/user'>
                            <i className="fas fa-user"></i>
                        </Link>
                    </li>
                ) : null}
            </ul>
        </nav>
    )
}

export default Navbar
