import React, {useState, useEffect} from 'react'
import firebase from 'firebase/app'

import './home.css'
import logo from '../../../media/logo-login.gif'
import disc from '../../../media/disc2.png'
import { Link } from 'react-router-dom'

function Home({userDb}) {

    const [eventInCourt, setEventInCourt] = useState(false)


    useEffect(() => {
        if (userDb) {
            if (userDb.currentEvent.EventInCourt) {
                setEventInCourt(userDb.currentEvent)
            }
        }

    }, [userDb])

    const pushCloseEvent = () => {
        const usersDB = firebase.database().ref('usersDB_Muznext');
        usersDB.once('value', () => {
            let db = usersDB.child(userDb.id);
            let currentEvent = {
                AccessCode: '',
                EventInCourt: false,
                nameOfTheParty: ''
            }
            db.update({ currentEvent });
        })
    }

    const closeTheParty = () => {
        pushCloseEvent()
        setEventInCourt(false)
    }

    return (
        <div className="container-home">
            <img src={logo} alt="logo" className='logo-app-home' />
            <div id='home'>
                <img
                    src={disc}
                    alt=""

                />
                {
                        eventInCourt ? (
                            <div className='box-eventInCourt'>
                                    <h3>Tu as déjà un évement en cour</h3>
                                    <Link className="link-router" to={`/my-event#access_token=${userDb.TOkenAccessSpotify}&token_type=Bearer&expires_in=3600`}
                                    >
                                        <button  className="btn-box-enventInCourt">Retourner à la soirée</button>
                                    </Link>
                                    <br /><br />
                                    <button className="btn-box-enventInCourt"
                                        onClick={() => closeTheParty()}
                                    >Fermer l'événement</button>
                            </div>
                        ) : (
                <Link to='/new-event'>
                    <button className="btn-go-party">Lance une Party</button>
                </Link>
                        )}
            </div>
        </div>
    )
}

export default Home
