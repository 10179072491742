import React, { useState, useEffect } from 'react'

export default function ViewPlaylist({
    playlistMuznext,
    idTrackNowPlay,
    setStateViewSelect,
    spotifyApi,
    setRemoveItemPlaylist
}) {
    const [playlist, setPlaylist] = useState([])

    useEffect(() => {
        if (playlistMuznext !== undefined) setPlaylist(playlistMuznext.tracks.items)
    }, [playlistMuznext])

    const removeTrackOfPlaylist = (item) => {
        var tracks = [{ uri: item.track.uri }];
        var playlistId = playlistMuznext.id;
        spotifyApi.removeTracksFromPlaylist(playlistId, tracks)
            .then(function (data) {
                setRemoveItemPlaylist(true)
            }, function (err) {
                console.log('Something went wrong!', err);
            });
    }

    return (
        <div >
            {
                playlist && playlist.length !== 0
                    ?
                    <ul>
                        <i
                            className="fas fa-backspace"
                            style={{ fontSize: "2em", marginLeft: '10px' }}
                            onClick={() => {
                                setStateViewSelect(false)
                            }}
                        ></i>
                        {
                            playlist.map((item, index) => {
                                return (
                                    <li
                                        className='playlistItem'
                                        style={item.track.id === idTrackNowPlay ? { background: 'linear-gradient(to right, orange, black)' } : { background: 'linear-gradient(to right, #000000, #434343)' }}
                                        key={index}
                                    >
                                        <img src={item.track.album.images[2].url} alt="youdj" />
                                        <span><strong className="artisteName"> {item.track.album.artists[0].name + ' : '}</strong>{item.track.name}</span><br /><br />
                                        <i className="far fa-trash-alt"
                                            onClick={() => removeTrackOfPlaylist(item)}
                                        ></i>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    :
                    (
                        <>
                            <i
                                className="fas fa-backspace"
                                style={{ fontSize: "2em", marginLeft: '10px' }}
                                onClick={() => {
                                    setStateViewSelect(false)
                                }}
                            ></i>
                            <br /><br />
                            <p align='center'>
                                Aucune piste dans la playliste de la soirée
                            </p> 
                            <br />
                            <p style={{fontWeight: 'bold', fontSize: '18px'}} align='center'>
                                Tu peux rajouter une musique en cliquant sur <br />
                                "Ajouter un Titre"
                            </p>
                            <br /><br />
                        </>)
            }
        </div>
    )
}
