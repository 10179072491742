

function SpotifyConnect() {

    const authEndpoint = 'https://accounts.spotify.com/authorize';
    const clientId = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_SPOTIFY_REDIRECT_URI;
    const scopes = [
        'ugc-image-upload',
        'user-read-playback-state',
        'user-modify-playback-state',
        'user-library-modify',
        'user-library-read',
        'user-top-read',
        'user-read-playback-position',
        'user-read-recently-played',
        'user-follow-read',
        'user-follow-modify',
        'user-read-email',
        'user-read-private',
        'user-read-currently-playing',
        'streaming',
        'app-remote-control',
        'playlist-modify-private',
        'playlist-read-collaborative',
        'playlist-modify-public',
        'playlist-read-private',
       
    ];

     // 'ugc-image-upload',
        // 'user-read-playback-state',
        // 'user-modify-playback-state',
        // 'user-library-modify',
        // 'user-library-read',
        // 'user-top-read',
        // 'user-read-playback-position',
        // 'user-read-recently-played',
        // 'user-follow-read',
        // 'user-follow-modify',
        // 'user-read-email',
        // 'user-read-private',
        // 'user-read-currently-playing',
        // 'streaming',
        // 'app-remote-control',
        // 'playlist-modify-private',
        // 'playlist-read-collaborative',
        // 'playlist-modify-public',
        // 'playlist-read-private',

    const accessUrl = `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
        '%20'
    )}&response_type=token&show_dialog=false`;

    window.location.href = accessUrl;

}

export default SpotifyConnect
